<template>
    <div class="nof">
        <h1>你要的页面未找到....</h1>

        <h2>404 NotFound.</h2>

        <a href="/">返回首页</a>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped>
    .nof {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        flex-direction: column;
    }
</style>